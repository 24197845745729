<template>
  <div class="ui-popup">
    <transition name="fade">
      <div
        v-if="value"
        @touchmove="onTouchMoveHandler"
        @click="handlerClose"
        class="overlay"
      />
    </transition>
    <transition name="move">
      <div
        v-if="value"
        class="ui-popup-card"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  components: {
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    handlerClose() {
      this.$emit('change', false);
    },
    onTouchMoveHandler(e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
