<template>
  <div
    :class="{'show-header': showHeader}"
    class="p-bind-phone"
  >
    <!-- <PageHeader
    v-if="showHeader"
    :menu-status="false"
    @back="onPageBackHandler"
  >
    绑定手机号
  </PageHeader> -->
    <PostHeader
      v-if="showHeader"
      :auto-back="false"
      :menu-status="false"
      @back="onPageBackHandler"
    >
      <span class="p-bind-phone__header-title">
        绑定手机号
      </span>
    </PostHeader>
    <div
      v-if="pageStatus===0"
      class="loading"
    />
    <div
      v-else-if="pageStatus===1"
      class="p-bind-phone__bind"
    >
      <div
        class="p-bind-phone__input-wrapper"
      >
        <div
          :class="{active:isPhoneInputFocus || phone}"
          class="p-bind-phone__phone-area"
        >
          CN+{{ countryCode }}
        </div>
        <input
          ref="phoneInput"
          v-model="phone"
          :class="{active:isPhoneInputFocus || phone}"
          @focus="onPhoneInputFocusHandler"
          @blur="onPhoneInputBlurHandler"
          @input="onPhoneInputInputHandler"
          class="p-bind-phone__phone-input"
          placeholder="请输入手机号"
          type="tel"
          maxlength="11"
        >
        <div
          :class="{active: phone}"
          @click="onClearIconClickHandler"
          class="p-bind-phone__phone-input-icon"
        />
      </div>
      <div
        class="p-bind-phone__button-wrapper"
      >
        <Button
          :type="phone.length===11 ? 'primary' : 'disabled'"
          :disabled="phone.length<11"
          @click="onSendCodeClickHandler"
          size="xl"
        >
          发送验证码
        </Button>
      </div>
    </div>
    <div
      v-else-if="pageStatus===2"
      class="p-bind-phone__unbind"
    >
      <div class="p-bind-phone__unbind-phone-image" />
      <div class="p-bind-phone__unbind-phone-text">
        你的手机号
      </div>
      <div class="p-bind-phone__unbind-phone-number">
        {{ phone }}
      </div>
      <div class="gap" />
      <div
        @click="onUnbindButtonClickHandler"
        class="p-bind-phone__unbind-button"
      >
        更换号码
      </div>
    </div>
    <div
      v-else-if="pageStatus===3"
    >
      <div class="p-bind-phone__send-tips">
        <div class="p-bind-phone__send-tips-inner">
          短信验证码已发送至 +{{ countryCode }}&nbsp;{{ phone }} 请注意查收
        </div>
      </div>
      <div
        @click="onCodeInputClickHandler"
        class="p-bind-phone__code-input-wrapper"
      >
        <div
          v-for="(num, index) in Array(6)"
          :key="index"
          class="p-bind-phone__code-input-item"
        >
          {{ captchaList[index] || '' }}
        </div>
        <input
          ref="codeInput"
          v-model="captchaInput"
          @input="onCodeInputInputHandler"
          class="p-bind-phone__code-input"
          type="tel"
        >
      </div>
      <div
        class="p-bind-phone__code-button-wrapper"
      >
        <Button
          :type="captchaList.length < 6 ? 'disabled' : 'primary'"
          :disabled="captchaList.length < 6"
          @click="onBindPhoneClickHandler"
          size="xl"
        >
          完成
        </Button>
      </div>
      <div
        v-if="codeClock > 0"
        class="p-bind-phone__code-timer"
      >
        {{ codeClock }}秒后可重新获取
      </div>
      <div
        v-else
        @click="onResendClickHandler"
        class="p-bind-phone__code-timer"
      >
        重新获取验证码
      </div>
    </div>
    <Popup
      v-model="unbindPopupShow"
      class="p-bind-phone__unbind-popup"
    >
      <div>
        <div
          @click="onUnbindConfirmButtonClickHandler"
          class="p-bind-phone__unbind-confirm-button"
        >
          解绑手机
        </div>
        <div class="gap" />
        <div
          @click="onUnbindCancelButtonClickHandler"
          class="p-bind-phone__unbind-cancel-button"
        >
          取消
        </div>
      </div>
    </Popup>
    <Dialog
      v-model="bindDialogShow"
      @confirm="onBindDialogConfirm"
      @cancel="onBindDialogCancel"
      confirm
      confirm-text="确认解绑"
      cancel-text="暂不解绑"
    >
      <div class="p-bind-phone__bind-dialog-content">
        {{ bindDialogMsg }}
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Button from '@/ui/button/index.vue';
import Popup from '@/ui/popup/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import userApi from '@/api/user';
import { isMobile } from '@/util/validate';
// eslint-disable-next-line no-unused-vars
import jsbridge, { send as noticeSend, popBack, showTaskToast } from '@/jsbridge';
import reportApi, { reportEnum } from '@/api/report';
// import PageHeader from './component/page-header/index.vue';
import PostHeader from '@/component/post-header/index.vue';
/* eslint-disable vue/no-unused-components */

export default {
  name: 'PBindPhone',
  components: {
    // PageHeader,
    Button,
    Popup,
    Dialog,
    // eslint-disable-next-line vue/no-unused-components
    PostHeader,
  },
  data() {
    return {
      showHeader: false, // 是否终端展示头部
      title: '绑定手机号',
      countryCode: '86',
      pageStatus: 0, // -1未登录，0 loading, 1: 未绑定手机，输入手机号，2：已绑定手机，3：输入验证码
      phone: '',
      isPhoneInputFocus: false,
      captcha: '',
      captchaInput: '',
      codeClock: 60,
      codeClockTimer: null,
      unbindPopupShow: false,
      bindDialogShow: false,
      bindDialogMsg: '',
      sendCaptchaLoading: false,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      userInfo: state => state.user.userInfo,
    }),
    captchaList() {
      return this.captchaInput.split('').filter(item => /[0-9]/.test(item))
        .slice(0, 6);
    },
    isPhoneValid() {
      return isMobile(this.phone);
    },
  },
  watch: {
    //
  },
  beforeCreate() {
    window.addEventListener('touchmove', (event) => {
      event.preventDefault();
    }, false);
  },
  mounted() {
    const { _gcwv: wv } = this.$route.query;
    if (Number(wv) === 2) {
      this.showHeader = true;
    }
    this.initPageData();
    // this.getNewUserInfo();
    jsbridge.core.call('ui/setBackListener', {
      action: 1, // 增加监听
      callback: () => {
        // console.log('setBackListener callback');
        this.$emit('change', false);
        this.onPageBackHandler();
      },
    });
  },
  beforeDestroy() {
    clearTimeout(this.codeClockTimer);
    jsbridge.core.call('ui/setBackListener', {
      action: 0, // 移除监听
    });
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
    }),
    initPageData() {
      this.getUserInfo().then(() => {
        if (!this.userInfo) {
          this.pageStatus = -1; // 未登录
          this.$checkAuthDeco();
        } else if (this.userInfo.phone) {
          this.pageStatus = 2; // 已经绑定手机号
          this.phone = this.userInfo.phone;
          this.report({
            moduleid: 1,
            eventtype: 2,
            extendid: 1,
          }, '已绑定手机号页面曝光');
        } else {
          this.pageStatus = 1; // 没有绑定手机号
          this.report({
            moduleid: 1,
            eventtype: 1,
            extendid: 1,
          }, '绑定手机号页面曝光');
        }
        this.getNewUserInfo();
      });
    },
    // 点击发送验证码
    onSendCodeClickHandler() {
      this.report({
        moduleid: 1,
        eventtype: 3,
        extendid: 1,
      }, '点击发送验证码');
      if (this.phone) {
        if (isMobile(this.phone)) {
          this.sendCaptcha();
        } else {
          this.$toast('手机号格式不正确');
        }
      } else {
        this.$toast('请输入手机号');
      }
    },
    sendCaptcha() {
      if (this.sendCaptchaLoading) return;
      this.sendCaptchaLoading = true;
      this.codeClock = 60;
      userApi.sendCaptcha({
        uid: this.userInfo && this.userInfo.uid,
        phone: this.phone,
        countryCode: this.countryCode,
      }).then(() => {
        this.pageStatus = 3; // 输验证码
        // 输入框自动获取焦点
        this.$nextTick(() => {
          const inputRef = this.$refs.codeInput;
          inputRef.focus();
        });
        // 设置定时器
        const timerEndTime = (new Date()).getTime() + 60 * 1000;
        const timerLoop = () => {
          if (this.codeClock > 0) {
            const now = (new Date()).getTime();
            this.codeClock = parseInt((timerEndTime - now) / 1000, 10);
            clearTimeout(this.codeClockTimer);
            this.codeClockTimer = setTimeout(() => {
              timerLoop();
            }, 1000);
          } else {
            clearTimeout(this.codeClockTimer);
          }
        };
        timerLoop();
        this.sendCaptchaLoading = false;
      })
        .catch((err) => {
        // TODO 统一错误处理
          if (err && err.data && err.data.msg) {
            this.report({
              moduleid: 1,
              eventtype: 6,
              extendid: 1,
              ext1: err.data.msg,
            }, '发送验证码失败');
          }
          this.report({
            moduleid: 1,
            eventtype: 6,
            extendid: 1,
            ext1: '未知错误',
          }, '发送验证码失败');
          this.sendCaptchaLoading = false;
        });
    },
    onPhoneInputFocusHandler() {
      this.isPhoneInputFocus = true;
    },
    onPhoneInputBlurHandler() {
      this.isPhoneInputFocus = false;
    },
    onPhoneInputInputHandler() {
      this.phone = this.phone.replace(/[^\d]/g, '');
    },
    // 假验证码输入框点击
    onCodeInputClickHandler() {
      const inputRef = this.$refs.codeInput;
      inputRef.focus();
    },
    onCodeInputInputHandler() {
      this.captchaInput = this.captchaInput.replace(/[^\d]/g, '').slice(0, 6);
    },
    onBindPhoneClickHandler() {
      this.report({
        moduleid: 1,
        eventtype: 3,
        extendid: 2,
      }, '输入验证码完成按钮点击');
      this.bindPhone();
    },
    bindPhone(isForceBind) {
      if (this.captchaList.length < 6) return;
      userApi.bindPhone({
        uid: this.userInfo.uid,
        phone: this.phone,
        countryCode: this.countryCode,
        captcha: this.captchaList.join(''),
        bindType: isForceBind ? 1 : 0, // 普通绑定：0，解绑再绑定：1
      }).then((res) => {
        // console.log('res ponse ', res);
        if (res.ret === 20001) {
          const bindUserInfo = res.userInfo;
          this.bindDialogMsg = `你所输入的${this.phone}手机号已绑定“${bindUserInfo.nickName}”账号，是否解除绑定？`;
          this.bindDialogShow = true;
          this.report({
            moduleid: 1,
            eventtype: 2,
            extendid: 1,
          }, '手机号已经绑定过别的账号弹窗曝光');
        } else if (res.ret === 0) {
          this.pageStatus = 2;
          this.captchaInput = '';
          this.report({
            moduleid: 1,
            eventtype: 6,
            extendid: 2,
          }, '手机绑定成功');
          noticeSend({
            noticeId: 'bindPhone',
            noticeEvent: 'bind',
            noticeParams: {
              phoneNumber: this.phone,
            },
          }).then(() => {
            // console.log('noticeSend');
          });
          // 提示积分
          if (res.missionRsp && res.missionRsp.ret === 0 && res.missionRsp.content) {
            showTaskToast({
              title: '绑定成功',
              integral: res.missionRsp.content.point,
              experience: res.missionRsp.content.exp,
            });
          } else {
            this.$toast('绑定成功');
          }
        }
      });
    },
    // 更换手机号
    onUnbindButtonClickHandler() {
      // showTaskToast({
      //   title: '绑定成功',
      //   integral: 231,
      //   experience: 123,
      //   addition: '额外信息',
      // });
      this.report({
        moduleid: 1,
        eventtype: 3,
        extendid: 4,
      }, '更换号码按钮点击');
      this.unbindPopupShow = true;
    },
    onUnbindConfirmButtonClickHandler() {
      this.report({
        moduleid: 1,
        eventtype: 3,
        extendid: 5,
      }, '解绑手机按钮点击');
      userApi.unbindPhone({
        uid: this.userInfo.uid,
      }).then(() => {
        this.$toast('解除绑定成功');
        this.phone = '';
        this.captchaInput = '';
        this.pageStatus = 1;
        this.unbindPopupShow = false;
        this.report({
          moduleid: 1,
          eventtype: 6,
          extendid: 3,
        }, '解绑手机成功');
        noticeSend({
          noticeId: 'bindPhone',
          noticeEvent: 'unbind',
          noticeParams: {},
        }).then(() => {
          // console.log('noticeSend');
        });
      });
    },
    onUnbindCancelButtonClickHandler() {
      this.unbindPopupShow = false;
    },
    onResendClickHandler() {
      this.captchaInput = '';
      this.pageStatus = 1;
    },
    onClearIconClickHandler() {
      this.phone = '';
      const phoneInputRef = this.$refs.phoneInput;
      phoneInputRef.focus();
    },
    onBindDialogConfirm() {
      this.bindDialogShow = false;
      this.report({
        moduleid: 1,
        eventtype: 3,
        extendid: 3,
      }, '手机号已绑定过别的-确认解绑按钮点击');
      this.bindPhone(true);
    },
    onBindDialogCancel() {
      this.bindDialogShow = false;
      this.phone = '';
      this.captchaInput = '';
      this.pageStatus = 1;
    },
    // 同步最新用户数据
    getNewUserInfo() {
      userApi.getUserLoginInfo({
        uid: this.userInfo.uid,
      })
        .then((res) => {
          const { userInfo: newUserInfo } = res;
          if (newUserInfo.phone && !this.userInfo.phone) {
            // 最新数据有手机号，但是app数据里面没有
            this.pageStatus = 2; // 已经绑定手机号
            this.phone = newUserInfo.phone;
            noticeSend({
              noticeId: 'bindPhone',
              noticeEvent: 'bind',
              noticeParams: {
                phoneNumber: newUserInfo.phone,
              },
            }).then(() => {
              // console.log('noticeSend');
            });
          } else if (!newUserInfo.phone && this.userInfo.phone) {
            // 如果最新数据没有手机号，但是app数据里面有
            this.phone = '';
            this.captchaInput = '';
            this.pageStatus = 1;
            noticeSend({
              noticeId: 'bindPhone',
              noticeEvent: 'unbind',
              noticeParams: {},
            }).then(() => {
              // console.log('noticeSend');
            });
          }
        });
    },
    // 自定义返回
    onPageBackHandler() {
      // console.log('onPageBackHandler');
      const { pageStatus } = this;
      if (!pageStatus || pageStatus === 1 || pageStatus === 2) {
        popBack();
        // console.log('pageStatus', pageStatus);
      } else if (pageStatus === 3) {
        this.captchaInput = '';
        this.pageStatus = 1;
      }
    },
    // 上报数据
    report(params, desc) {
      const obj = {
        indexid: 1,
        pageid: reportEnum.pageid.BIND_PHONE,
        ...params,
      };
      reportApi(obj, desc);
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
